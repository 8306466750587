
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <form @submit.prevent="addShopInvoiceItems" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="shop_invoices" v-model="shop_invoice_items.shop_invoice_id" dense  filled outlined  item-text="shop_invoice_id"
                                    item-value="shop_invoice_id" :return-object="false" :label="$store.getters.language.data.shop_invoices.shop_invoice_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="shop_items" v-model="shop_invoice_items.shop_item_id" dense  filled outlined  item-text="shop_item_id"
                                    item-value="shop_item_id" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['pending','complete','returned']" v-model="shop_invoice_items.shop_invoice_item_status" dense  filled outlined  item-text="shop_invoice_item_status"
                                    item-value="shop_invoice_item_status" :return-object="false" :label="$store.getters.language.data.shop_invoice_items.shop_invoice_item_status">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="shop_invoice_items.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.shop_invoice_items.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="shop_invoice_item_id">
                    <template v-slot:[`item.shop_invoice_item_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/shop_invoice_items-list/'+item.shop_invoice_item_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectShopInvoiceItems(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteShopInvoiceItemsList">{{$store.getters.language.data.shop_invoice_items.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.shop_invoice_items.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.shop_invoice_items.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteShopInvoiceItems(selected_shop_invoice_items.shop_invoice_item_id)">
                {{$store.getters.language.data.shop_invoice_items.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/shop_invoice_items.request.js'
    export default {
        data() {
            return {
                shop_invoice_items: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_shop_invoice_items : {},
                delete_dialog: false,
                headers: [

                    
                    {
                        text: this.$store.getters.language.data.shop_invoices.shop_invoice_id,
                        align: 'start',
                        sortable: true,
                        value: 'shop_invoice_id',
                    },
                    {
                        text: this.$store.getters.language.data.shop_items.shop_item_id,
                        align: 'start',
                        sortable: true,
                        value: 'shop_item_id',
                    },
                        { 
                            text: this.$store.getters.language.data.shop_invoice_items.shop_invoice_item_status,
                            align: 'start',
                            sortable: true,
                            value: 'shop_invoice_item_status',
                        },
                    {
                        text: this.$store.getters.language.data.users.user_id,
                        align: 'start',
                        sortable: true,
                        value: 'user_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'shop_invoice_item_id',
        }
                ],
            }
        },
        computed: {
            shop_invoices(){
                return this.$store.getters.shop_invoices_list
            },
            shop_items(){
                return this.$store.getters.shop_items_list
            },
            users(){
                return this.$store.getters.users_list
            },
        },
        mounted(){
            this.readShopInvoiceItems();
        },
        methods: {
            addShopInvoiceItems() {
                this.loading_btn = true
                requests.createShopInvoiceItems(this.shop_invoice_items).then(r => {
                    if (r.status == 200) {
                        this.shop_invoice_items = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'ShopInvoiceItems Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add ShopInvoiceItems',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteShopInvoiceItems(shop_invoice_item_id) {
                requests.deleteShopInvoiceItems(shop_invoice_item_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.shop_invoice_item_id != shop_invoice_item_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'ShopInvoiceItems Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteShopInvoiceItemsList() {
                let ids = this.selected_rows.map(m => m.shop_invoice_item_id)
                requests.deleteShopInvoiceItemsList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.shop_invoice_item_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' ShopInvoiceItems Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readShopInvoiceItems() {
                this.loading = true
                requests.getAllShopInvoiceItems().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ShopInvoiceItems',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ShopInvoiceItems',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectShopInvoiceItems(i) {
                this.selected_shop_invoice_items = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    